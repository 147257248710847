@import url('https://fonts.googleapis.com/css?family=NanumGothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap');

html {
    width: 100%;
    min-height: 100%;
    overflow: auto;
}

html,
body {
    margin: 0;
    background-color: #ffffff;
    font-family: 'NanumGothic', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body,
#root {
    width: 100%;
    height: 100%;
}
